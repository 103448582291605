<template>
  <el-dialog :visible.sync="visible" width="75%" class="dialog_main" append-to-body title="预览">
    <video :src="videoSrc" class="image" autoplay loop></video>
  </el-dialog>
</template>

<script>
export default {
  name: 'BigImg',
  data() {
    return {
      visible: false,
      videoSrc: ''
    }
  },
  methods: {
    show(value) {
      this.visible = true
      this.videoSrc = value
    }
  }
}
</script>

<style scoped lang="scss">
.dialog_main {
  ::v-deep .el-dialog {
    margin: 20px auto !important;
    .el-dialog__header {
      padding: 10px;
    }
    .el-dialog__headerbtn {
      top: 12px;
    }
    .el-dialog__body {
      padding: 0;
      height: calc(100% - 108px);
    }
  }
  .image {
    width: 100%;
    vertical-align: bottom;
  }
}
</style>
